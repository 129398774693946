import isServerSide from '@helpers/misc/isServerSide'

import {OrionApolloClientOpts} from './types'

let options: Partial<OrionApolloClientOpts> = {}

export const setOptions = function (newOptions: Partial<OrionApolloClientOpts>) {
  if (isServerSide()) return
  options = newOptions
}

export const getOptions = function () {
  if (isServerSide()) throw new Error('getOptions should not be called on the server')
  return options
}
