import {useContext} from 'react'
import {ClientsContext} from '@providers/apollo/ApolloWrapper'
import {OrionApolloClient} from '@providers/apollo/createApolloClient/orion'

export function useClients(): {[key: string]: OrionApolloClient<any>} {
  return useContext(ClientsContext)
}

export function useClient(clientName: string): OrionApolloClient<any> {
  const clients = useClients()

  return clients[clientName]
}
