'use client'

import useSWR from 'swr'

export default function useBuildId() {
  const {data} = useSWR('/api/build-id', async (url) => {
    const response = await fetch(url)
    return response.json()
  }, {refreshInterval: 1000 * 60})


  return data?.buildId
}