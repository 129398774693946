'use client'
import {InMemoryCache} from '@apollo/client'
import {WRAPPED_WEBSITE_ID_KEY} from '@components/WrappedWebsite/constants'
import getDeviceId from '@helpers/auth/getDeviceId'
import getFingerprint from '@helpers/auth/getFingerprint'
import isServerSide from '@helpers/misc/isServerSide'
import getDomain from '@helpers/websites/getDomain'
import {localStorageGetItem} from '@hooks/useLocalStorageState'
import {getSavedValue} from '@hooks/useSessionStorageState'
import {clientNames} from '@providers/services'

import refreshJWT from '../refreshJWT'

import {isJustoApp} from '@helpers/misc/isJustoApp'
import getTimezone from './getTimezone'
import {OrionApolloClient, createClient} from './orion'

declare const window: {
  __NEXT_DATA__: {
    apolloState: any
  }
  location: {
    pathname: string
  }
}

function mergePaginatedResults(existing: any = {}, incoming: any = {}) {
  const existingIds = new Set(existing.items?.map(item => item.__ref))
  const incomingItems = (incoming.items ?? []).filter(item => !existingIds.has(item.__ref))

  if (!incomingItems.length) return existing

  return {
    ...existing,
    ...incoming,
    items: [...(existing.items ?? []), ...incomingItems],
  }
}

export default function createApolloClient({
  clientName,
  endpointURL,
  useSubscriptions,
  restoreCache,
}): OrionApolloClient<any> {
  const cache = new InMemoryCache({
    dataIdFromObject: object => {
      if ((object as any)._id) return `${object.__typename}:${(object as any)._id}`
      if (object.id) return `${object.__typename}:${object.id}`
      if (object.__typename === 'Cart') return 'cart'
    },
    typePolicies: {
      Query: {
        fields: {
          // This allows merging pagination results into a single array
          products: {
            keyArgs: ['categoryId', 'websiteId', 'menuId'],
            merge(existing = {}, incoming = {}) {
              return mergePaginatedResults(existing, incoming)
            },
          },
          categoriesInMenu: {
            // We need to use limit here, otherwise the full query does not get updated as it believes it is cached when the minimal (category navbar) is completed
            keyArgs: ['websiteId', 'menuId', 'limit'],
            merge(existing = {}, incoming = {}) {
              return mergePaginatedResults(existing, incoming)
            },
          },
          categories: {
            keyArgs: ['websiteId', 'menuId'],
            merge(existing = {}, incoming = {}) {
              return mergePaginatedResults(existing, incoming)
            },
          },
        },
      },
      // https://www.apollographql.com/docs/react/caching/cache-field-behavior/#merging-non-normalized-objects
      Schedule: {
        merge: true,
      },
      Country: {
        merge: true,
      },
      WebsiteDesign: {
        merge: true,
      },
      ProductAvailability: {
        merge: true,
      },
      StoreAddress: {
        merge: true,
      },
      MenuSectionConfiguration: {
        merge: true,
      },
      Cart: {
        fields: {
          loyaltyTransactionStatus: {
            merge: true,
          },
        },
        merge: true,
      },
      UserPreferences: {
        keyFields: () => 'Preference',
      },
      Preference: {
        // This will allow using the Preference object as a singleton cache entity
        keyFields: () => 'Preference',
        fields: {
          cart: {
            merge: true,
          },
        },
      },
      CouponStatus: {
        merge: true,
      },
    },
  })

  if (restoreCache && !isServerSide()) {
    // Injected by _document.tsx
    cache.restore(window.__NEXT_DATA__.apolloState?.[clientName])
  }

  const client = createClient({
    ssrMode: isServerSide(),
    endpointURL,
    useSubscriptions,
    cache,
    fetchTimeout: isServerSide() ? 15000 : null,
    usePersistedQueries: true,
    batch: false,
    apolloOverrides: {
      name: 'websites',
      version: process.env.COMMIT_HASH ?? 'local',
      // Disable the Apollo cache on the server-side
      // Othwerwise, RAM usage will keep growing on production as the server stores more and more website data.
      defaultOptions: isServerSide()
        ? {
            watchQuery: {
              fetchPolicy: 'no-cache',
            },
            query: {
              fetchPolicy: 'no-cache',
            },
          }
        : {},
    },
    resolvers: {},
    getHeaders(_body) {
      if (isServerSide()) return {}
      const timezone = getTimezone() // TODO: review headers on the server side
      const storedWebsiteId = getSavedValue(WRAPPED_WEBSITE_ID_KEY)

      return {
        'X-ORION-DEVICEID': getDeviceId(),
        'X-ORION-PLATFORM': isJustoApp() ? 'orion' : 'web',
        'X-ORION-FP': getFingerprint(),
        'X-ORION-DOMAIN': getDomain() || '',
        'X-ORION-REFERRER': document.referrer,
        'X-ORION-TIMEZONE': timezone,
        'X-ORION-PATHNAME': window.location.pathname,
        ...(clientName === clientNames.main ? {'X-ORION-WEB-VERSION': '2'} : {}),
        ...(storedWebsiteId ? {'X-ORION-WRAPPED-WEBSITE': storedWebsiteId} : {}),
      }
    },
    saveSession(_session) {},
    getSession(_session) {
      return {}
    },
    getJWT: () => (isServerSide() ? null : localStorageGetItem('justo_jwt_token') || null),
    refreshJWT,
    refreshJWTEvery: 1000 * 60 * 25,
  })

  return client
}
