export default class JustoError extends Error {
  constructor(errMessage, data, type = 'error') {
    // Passes errMessage to the Error super class,
    // similar to call new Error(errMessage).
    super(errMessage)

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, JustoError)
    }

    this.name = 'JustoError'
  }
}
