import React from 'react'
import useForceUpdate from '@hooks/useForceUpdate'

const Context = React.createContext(null)

function GlobalState(props) {
  const forceUpdate = useForceUpdate()
  return <Context.Provider value={forceUpdate}>{props.children}</Context.Provider>
}

export {GlobalState, Context}
