import gql from 'graphql-tag'

export default gql`
  fragment footer on Query {
    website(websiteId: $websiteId) {
      _id
      name
      logo {
        _id
        url: resizedURL(height: 200, format: "webp")
      }
      terms(excludeContent: true)
      paymentTypesDescription(excludeContent: true)
      pointsConfiguration {
        name
      }
      footerConfiguration {
        logo {
          _id
          url: resizedURL(height: 200, format: "webp")
        }
        backgroundColor
        textColor
        instagram
        facebook
        twitter
        links {
          title
          path
          httpPostOptions {
            enabled
            parameters {
              name
              value
            }
          }
        }
      }
    }
  }
`
