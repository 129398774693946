import encryptString from '@page-components/AnalyticsV2/utils/encryptString'

function formatPixelUserInfo(userInfo, extraInfo) {
  if (!userInfo) return {}
  const formattedUserInfo = {
    em: userInfo.email,
    ph: userInfo.phone ? userInfo.phone.replace('+', '') : null,
    fn: userInfo.profile.firstName,
    ln: userInfo.profile.lastName,
    ct: null,
    db: null,
    country: extraInfo?.countryCode,
    ge: null,
    st: null,
    zp: null
  }
  const fieldsNotToEncrypt = ['country']
  for (const [key, value] of Object.entries(formattedUserInfo)) {
    if (!fieldsNotToEncrypt.includes(key)) {
      formattedUserInfo[key] = encryptString(value)
    }
  }
  return formattedUserInfo
}

export default formatPixelUserInfo
