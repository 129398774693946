import {DocumentNode, OperationVariables, SuspenseQueryHookOptions, useQuery, useSuspenseQuery} from '@apollo/client'
import isServerSide from '@helpers/misc/isServerSide'


export default function useSsrQuery<TData, TVariables extends OperationVariables = OperationVariables>(
  query: DocumentNode,
  options: SuspenseQueryHookOptions<TData, TVariables>
) {
  // We skip suspense query in the client-side to avoid corner case where JWT is expired and the suspense query throws an error
  const ssrQueryResult = useSuspenseQuery<TData, TVariables>(query, {
    ...options,
    fetchPolicy: 'cache-first',
    skip: !isServerSide() || (options.skip ?? false),
  })

  // We skip the client side query if we already fetched the info in the previous query
  const clientSideResult = useQuery<TData, TVariables>(query, {
    ...options,
    fetchPolicy: 'cache-first',
    returnPartialData: false,
    skip: isServerSide() || !!ssrQueryResult?.data || (options.skip ?? false)
  })

  return clientSideResult?.data ? clientSideResult : ssrQueryResult
}