const SENTRY_INFORMED_ERRORS_KEY = 'sentryInformedErrors'

export function eventWasInformed(event) {
  if (!localStorage) return false
  try {
    const storagedErrors = localStorage.getItem(SENTRY_INFORMED_ERRORS_KEY)
    const {
      exception: {values},
      release
    } = event
    if (!values[0]) return false
    //se construye el nuevo evento, ya sea para validar si existe o para guardarlo en el storage
    const newEvent = {
      type: values[0].type,
      value: values[0].value,
      release: release
    }
    //se extrae el array de errores ya enviados
    const informedErrors = JSON.parse(storagedErrors)
    //se verifica si el error existe en el listado de los ya informados
    if (
      Array.isArray(informedErrors) &&
      informedErrors.some(
        informed =>
          informed.type === newEvent.type &&
          informed.value === newEvent.value &&
          informed.release === newEvent.release
      )
    ) {
      return true
    } else {
      //si no existe se añade el nuevo evento al storage
      localStorage.setItem(
        SENTRY_INFORMED_ERRORS_KEY,
        JSON.stringify([newEvent, ...(Array.isArray(informedErrors) ? informedErrors : [])])
      )
      return false
    }
  } catch (error) {
    return false
  }
}

export function eventIsFromGTM(event, hint) {
  let gtm = false
  const random = Math.floor(Math.random() * 10)
  try {
    gtm = hint.originalException.stack.indexOf('www.googletagmanager.com/gtm.js?id=') > -1
  } catch (error) {
    return null
  }
  event.tags = event.tags || {}
  if (gtm) {
    event.tags.logger = 'gtm'
  }
  event.tags.logger = event.tags.logger || '_default_'
  return random === 0 ? event : null
}
