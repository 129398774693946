import {useSuspenseQuery} from '@apollo/client'
import {
  WebsiteInitialData_CachedQuery,
  WebsiteInitialData_CachedQueryVariables
} from '@data/__generated__/types.main'
import footer from '@data/fragments/website/initialData/footer.main'
import importantMessage from '@data/fragments/website/initialData/importantMessage.main'
import layout from '@data/fragments/website/initialData/layout.main'
import navbar from '@data/fragments/website/initialData/navbar.main'
import useWebsite from '@data/fragments/website/initialData/useWebsite.main'
import websiteRoutes from '@data/fragments/website/initialData/websiteRoutes.main'
import getClient from '@providers/apollo/getClient'
import gql from 'graphql-tag'

export const initialDataQuery = gql`
  query websiteInitialData_cached($websiteId: ID) {
    ...layout
    ...websiteRoutes
    ...importantMessage
    ...navbar
    ...footer
    ...useWebsite
  }
  ${layout}
  ${importantMessage}
  ${websiteRoutes}
  ${navbar}
  ${footer}
  ${useWebsite}
`

export default async function getWebsiteInitialData(websiteId?: string) {
  if (!websiteId) {
    return null
  }
  const {data} = await getClient().query<
    WebsiteInitialData_CachedQuery,
    WebsiteInitialData_CachedQueryVariables
  >({
    query: initialDataQuery,
    variables: {
      websiteId
    }
  })

  return data
}

export function useWebsiteInitialDataQuery(websiteId?: string) {
  const {data} = useSuspenseQuery<
    WebsiteInitialData_CachedQuery,
    WebsiteInitialData_CachedQueryVariables
  >(initialDataQuery, {
    variables: {websiteId},
    skip: !websiteId,
    fetchPolicy: 'cache-first'
  })

  return data
}