import {useUserPreferencesPartial} from '@data/queries/preferences/userPreferences.main'
import useWebsiteId from '@hooks/useWebsiteId'
import useInitialData from '@page-components/Layout/useInitialData'
import useLayout from '@page-components/Order/useLayout'

function useBaseProperties() {
  const websiteId = useWebsiteId()
  const initialData = useInitialData()
  const prefs = useUserPreferencesPartial()
  const layout = useLayout()

  return {
    storeId: prefs?.data?.userPreferences?.storeId,
    websiteId,
    websiteUrl: initialData?.website?.baseURL,
    countryCode: initialData?.website?.countryCode,
    deviceType: layout === 'mobile' ? 'mobile' : 'desktop'
  }
}

export default useBaseProperties
