import getJWTData from './getJWTData'
import onSessionSaved from './onSessionSaved'

export default async function saveJWT({
  token,
  refreshToken
}: {
  token?: string
  refreshToken?: string
}) {
  if (!token) {
    localStorage.removeItem('justo_jwt_token')
    localStorage.removeItem('justo_jwt_refresh')
    localStorage.removeItem('justo_jwt_data')

    // session changed
    await onSessionSaved(null)
    return
  }

  localStorage.setItem('justo_jwt_token', token)

  const data = getJWTData(token)
  localStorage.setItem('justo_jwt_data', JSON.stringify(data))

  if (refreshToken) {
    localStorage.setItem('justo_jwt_refresh', refreshToken)

    // session changed
    return await onSessionSaved(data)
  }
}
