import {useQuery, useSuspenseQuery} from '@apollo/client'
import {
  UserPreferencesV2Query,
  UserPreferencesV2QueryVariables,
} from '@data/__generated__/types.main'
import useWebsiteId from '@hooks/useWebsiteId'
import getClient from '@providers/apollo/getClient'
import {clientNames} from '@providers/services'
import gql from 'graphql-tag'

export const userPreferencesQuery = gql`
  query userPreferencesV2($websiteId: ID!) {
    userPreferences(websiteId: $websiteId) {
      _id
      menuId
      storeId
      tableName
      time
      deliverAt
      deliveryType
      userId
    }
  }
`

export const useUserPreferences = (websiteId?: string) => {
  const savedWebsiteId = useWebsiteId()
  websiteId = websiteId ?? savedWebsiteId

  const result = useSuspenseQuery<UserPreferencesV2Query, UserPreferencesV2QueryVariables>(
    userPreferencesQuery,
    {
      variables: {websiteId},
      skip: !websiteId,
      fetchPolicy: 'cache-first',
    },
  )
  return result?.data?.userPreferences
}

export const useUserPreferencesPartial = (websiteId?: string, opts?: {skip?: boolean}) => {
  const savedWebsiteId = useWebsiteId()
  websiteId = websiteId ?? savedWebsiteId

  const res = useQuery<UserPreferencesV2Query, UserPreferencesV2QueryVariables>(
    userPreferencesQuery,
    {
      variables: {websiteId},
      skip: !websiteId || opts?.skip,
      returnPartialData: false,
      fetchPolicy: 'cache-first',
    },
  )
  return res
}

export const getUserPreferences = async (websiteId: string) => {
  const client = getClient(clientNames.main)
  if (!websiteId) return null
  const {data} = await client.query<UserPreferencesV2Query, UserPreferencesV2QueryVariables>({
    query: userPreferencesQuery,
    variables: {websiteId},
  })

  return data.userPreferences
}
