import {IncomingMessage} from 'node:http'
import {NextApiRequest} from 'next'
import {NextRequest} from 'next/server'

const isNextRequest = (req: NextApiRequest | NextRequest | IncomingMessage): req is NextRequest => {
  return (req as NextRequest)?.headers?.get !== undefined
}

export function getNormalizedHostname(hostname: string | string[], withWWW = false) {
  if (!hostname) return null
  if (Array.isArray(hostname)) {
    return getNormalizedHostname(hostname[0])
  }

  const result = hostname.split(':')[0]
  if (withWWW) {
    return result
  }

  return result.replace('www.', '')
}

export default function getNormalizedHostnameFromReq(
  req: NextRequest | NextApiRequest | IncomingMessage,
) {
  // Get hostname (e.g. mywebsite.com or website.local)
  const hostname = isNextRequest(req) ? req.headers.get('host') : req.headers.host
  return getNormalizedHostname(hostname)
}

export function getNormalizedHostnameFromReqWithWWW(
  req: NextRequest | NextApiRequest | IncomingMessage,
) {
  // Get hostname (e.g. mywebsite.com or website.local)
  const hostname = isNextRequest(req) ? req.headers.get('host') : req.headers.host
  return getNormalizedHostname(hostname, true)
}
