import fireEvent from 'react-app-events/lib/fireEvent'

import getClients from './getClients'

export default async function onSessionSaved(session) {
  const clients = getClients()
  const promises = Object.keys(clients).map(async clientName => {
    try {
      const client = clients[clientName]

      await client.resetStore()
      await client.reFetchObservableQueries()

      if (client._wsClient) {
        // @ts-expect-error ts-migrate(2341) FIXME: Property 'tryReconnect' is private and only access... Remove this comment to see the full error message
        client._wsClient.tryReconnect()
      }
    } catch (error) {
      console.log(`Error refetching apollo queries for client ${clientName}:`, error)
    }
  })

  await Promise.all(promises)

  fireEvent('saveSession', session || {})
}
