import React, {useContext} from 'react'
import {ApolloProvider as OriginalApolloProvider} from '@apollo/client' // Used inside AutoForm
import {ApolloProvider as HooksApolloProvider} from 'apollo-hooks'

import {OrionApolloClient} from '../createApolloClient/orion'
import getClients from '../getClients'

const SingletonContext = React.createContext(null)
export const ClientsContext = React.createContext(null)

export default function ApolloWrapper({
  children,
  overrideClients
}: {
  children: React.ReactNode
  overrideClients?: {[key: string]: OrionApolloClient<any>}
}) {
  // During SSR, _document.tsx will overwrite the main client by wrapping App with ApolloWrapper and passing a custom client.
  // We want to use that client for all further queries during SSR, so we don't want to wrap App with ApolloWrapper again.
  // This context is used to detect if we're already inside an ApolloWrapper and prevent double wrapping.
  const context = useContext(SingletonContext)
  if (context !== null) {
    return <>{children}</>
  }

  const clients = overrideClients ? overrideClients : getClients()

  return (
    <SingletonContext.Provider value={true}>
      <ClientsContext.Provider value={clients}>
        <HooksApolloProvider {...clients} client={clients.main}>
          <OriginalApolloProvider client={clients.main}>{children}</OriginalApolloProvider>
        </HooksApolloProvider>
      </ClientsContext.Provider>
    </SingletonContext.Provider>
  )
}
