import {useContext} from 'react'
import logger from '@helpers/logger'
import isServerSide from '@helpers/misc/isServerSide'
import {Context} from '@providers/GlobalState'

const getSavedValue = function <T = any>(key: string, defaultValue: T): T | void {
  if (isServerSide()) return defaultValue
  if (!localStorage) return defaultValue

  const savedValue = localStorageGetItem(key, null)
  if (savedValue === null) {
    return defaultValue
  } else {
    try {
      return JSON.parse(savedValue) as T
    } catch (error) {
      logger.error('[getSavedValue] : Error parsing to JSON saved value in local storage', {
        key,
        savedValue,
        error
      })
      return defaultValue
    }
  }
}

export default function useLocalStorageState<T = any>(
  key: string,
  defaultValue: T
): [T | void, (value: T) => void] {
  const value = getSavedValue<T>(key, defaultValue)
  const forceUpdate = useContext(Context)

  const setValue = (newValue: T) => {
    localStorage.setItem(key, JSON.stringify(newValue))
    forceUpdate()
  }

  return [value, setValue]
}

export function localStorageGetItem(key: string, valueOnError?: string) {
  if (isServerSide()) return valueOnError
  if (!localStorage) return valueOnError

  try {
    const value = localStorage.getItem(key)
    return value
  } catch (error) {
    logger.error(`Error getting local storage`, error)
    return valueOnError
  }
}
