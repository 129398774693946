import isServerSide from '@helpers/misc/isServerSide'
const hostname = isServerSide() ? '' : window.location.hostname
const isDev = hostname.includes('bejusto.com') || process.env.REACT_APP_DOMAIN
const isLocal =
  hostname.endsWith('.local') ||
  hostname.endsWith('.internal') ||
  hostname.includes('localhost') ||
  hostname.includes('100.1.20.187') ||
  hostname.includes('192.168.') ||
  hostname.includes('.ngrok.io') ||
  hostname.includes('10.0.')
const isProduction = !isDev && !isLocal

const forceProd = process.env.REACT_APP_FORCE_PRODUCTION

export type JustoEnv = 'prod' | 'dev' | 'local'

export default function getEnv(): JustoEnv {
  if (forceProd) return 'prod'

  if (process.env.JUSTO_ENV) return process.env.JUSTO_ENV as JustoEnv

  if (isProduction) return 'prod'

  return isDev ? 'dev' : 'local'
}
