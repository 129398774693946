import {useEffect, useRef} from 'react'
import {useRouter} from 'next/router'

import useBuildId from './useBuildId'

function usePrevious(value) {
  const ref = useRef()

  useEffect(() => {
    ref.current = value
  })

  return ref.current
}

export default function useReloadOnNewVersion() {
  const buildId = useBuildId()
  const prevBuildId = usePrevious(buildId)
  const router = useRouter()

  useEffect(() => {
    if (prevBuildId && buildId && prevBuildId !== buildId) {
      router.reload()
    }
  }, [buildId, prevBuildId])
}
