import gql from 'graphql-tag'

export default gql`
  fragment useWebsite on Query {
    website(websiteId: $websiteId) {
      isDeactivatedByDebt
      _id
      name
      description
      deliveryTypes
      minimumOrderPrice
      menuLayout
      productsLayout
      headerLayout
      useHTTPSecurityHeaders
      disableComplaints
      defaultMenuId
      businessType
      baseURL
      dontShowPricesUntilStoreSelected
      loginWithExternalProvidersAvailable
      categories {
        _id
        name
      }
    }
  }
`
