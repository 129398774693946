import isMainDomain from '@helpers/misc/domain/isMainDomain'
import isServerSide from '@helpers/misc/isServerSide'
import {localStorageGetItem} from '@hooks/useLocalStorageState'

export default function getDomain() {
  if (isServerSide()) return null // Only client-side
  if (isMainDomain()) return null

  if (localStorage && localStorageGetItem('salesPointDomain')) {
    return localStorageGetItem('salesPointDomain')
  } else {
    return window.location.hostname
  }
}
