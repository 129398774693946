import useWidth from '@hooks/useWidth'
import useInitialData from '@page-components/Layout/useInitialData'

export default function useLayout(): 'categories' | 'mobile' | 'v2' | string {
  const {website} = useInitialData()
  const width = useWidth()
  const isMobile = width < 768
  if (website && website.menuLayout === 'categories') return 'categories'

  if (isMobile) return 'mobile'
  if (!website) return 'v2'
  if (!website.menuLayout) return 'v2'
  return website.menuLayout
}
