import React from 'react'
import {WebsiteInitialData_CachedQuery} from '@data/__generated__/types.main'
import {useWebsiteInitialDataQuery} from '@data/queries/website/websiteInitialData.main'


const InitialDataContext = React.createContext<WebsiteInitialData_CachedQuery>(null)

export default InitialDataContext

export const WebsiteInitialDataProvider = ({websiteId, children}: {websiteId: string, children: React.ReactNode}) => {
  const initialData = useWebsiteInitialDataQuery(websiteId)

  return (
    <InitialDataContext.Provider value={initialData}>
      {children}
    </InitialDataContext.Provider>
  )
}
