import React from 'react'

import getColors from './WebsiteData/Styles/getColors'

export default function Styles(props) {
  const colors = getColors({
    backgroundColor: '#fff',
    textColor: '#111',
    descriptionTextColor: '#999',
    lighterBackgroundColor: '#fff',
    backgroundBorderColor: '#eee',
    color: '#265fe4',
    navbar: {
      backgroundColor: '#ffffff',
      buttonColor: '#2866ff',
      hintColor: '#2866ff',
      textColor: '#333333',
      upperBarColor: '#2866ff'
    }
  })

  return (
    <>
      <style jsx global>{`
        @import url('https://files.getjusto.com/fonts/bould/stylesheet.css');

        body {
          font-family: 'Bould', sans-serif;
        }

        input {
          font-family: 'Bould', sans-serif;
        }
      `}</style>
      {colors}
    </>
  )
}
