import {useApolloQuery} from 'apollo-hooks'
import gql from 'graphql-tag'

async function useLoadUserInfo(active: boolean) {
  const {me} = await useApolloQuery({
    query: gql`
      query getUserForAnalytics {
        me {
          _id
          email
          name
          phone
          profile {
            firstName
            lastName
          }
          createdAt
        }
      }
    `,
    fetchPolicy: 'cache-first',
    omit: !active,
    partial: true
  })
  return me
}

export default useLoadUserInfo
