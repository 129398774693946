import gql from 'graphql-tag'

export default gql`
  fragment navbar on Query {
    website(websiteId: $websiteId) {
      _id
      logo {
        _id
        url: resizedURL(height: 200, format: "webp")
      }
      navbarConfiguration {
        logo {
          _id
          url: resizedURL(height: 200, format: "webp")
        }
        backgroundColor
        textColor
        buttonColor
        links {
          title
          path
          showMegaMenu
          showLoggedIn
        }
      }
      pointsConfiguration {
        active
        name
      }
      categories {
        _id
        name
      }
    }
  }
`
