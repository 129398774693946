import {
  GetUserExperimentBranchQuery,
  GetUserExperimentBranchQueryVariables,
} from '@data/__generated__/types.experiments'
import getDeviceId from '@helpers/auth/getDeviceId'
import isServerSide from '@helpers/misc/isServerSide'
import useInitialData from '@page-components/Layout/useInitialData'
import {clientNames} from '@providers/services'
import gql from 'graphql-tag'

import {useClient} from './useClient'
import useSsrQuery from './useSsrQuery'
import useUserId from './useUserId'

export default function useExperiment(
  experimentId: string,
  /** If the experiment is non-sticky, prefer allowServerSide = true to prevent recalculating on every render */
  {allowServerSide = false}: {allowServerSide?: boolean} = {},
) {
  const {website} = useInitialData()
  let deviceId = getDeviceId()
  let userId = useUserId()
  if (allowServerSide) {
    // If we don't do this, the client-side will have to refetch given the query params changed.
    deviceId = undefined
    userId = undefined
  }

  const client = useClient(clientNames.experiments)

  const {data} = useSsrQuery<GetUserExperimentBranchQuery, GetUserExperimentBranchQueryVariables>(
    gql`
      query getUserExperimentBranch(
        $experimentId: String!
        $deviceId: String
        $websiteId: String
        $userId: String
        $countryCode: String
      ) {
        getUserExperimentBranch(
          experimentId: $experimentId
          websiteId: $websiteId
          userId: $userId
          deviceId: $deviceId
          countryCode: $countryCode
        ) {
          branchName
          createdAt
        }
      }
    `,
    {
      client,
      variables: {
        experimentId,
        websiteId: website?._id,
        userId,
        deviceId,
        countryCode: website?.countryCode,
      },
      skip: (allowServerSide ? false : isServerSide()) || !experimentId,
    },
  )

  return data?.getUserExperimentBranch?.branchName
}

export const withExperiment = (
  experimentId: string,
  allowServerSide: boolean,
  Component: React.ComponentClass<{
    experimentBranchName?: string
  }>,
) => {
  // eslint-disable-next-line react/display-name
  return props => {
    const branchName = useExperiment(experimentId, {allowServerSide})

    return <Component {...props} experimentBranchName={branchName} />
  }
}
