import isServerSide from '@helpers/misc/isServerSide'

let fingerprint = null

const loadFingerprint = async () => {
  if (isServerSide()) return
  const FingerprintJS = await (await import('@fingerprintjs/fingerprintjs')).default
  const fp = await FingerprintJS.load()

  const result = await fp.get()

  fingerprint = result.visitorId
}

setTimeout(() => {
  loadFingerprint()
}, 1)

export default function getFingerprint() {
  return fingerprint
}
