import {lightenColor} from '@helpers/misc/lightenDarkenColor'

export default function getColors({
  backgroundColor,
  textColor,
  descriptionTextColor,
  lighterBackgroundColor,
  backgroundBorderColor,
  color,
  navbar,
}) {
  const lightPrimary = lightenColor(color, 60)

  return (
    <style jsx global>{`
      :root {
        --text: ${textColor};
        --background-color: ${backgroundColor};
        --description-text-color: ${descriptionTextColor};
        --primary-color: ${color};
        --primary-color-10: ${color}10;
        --lighter-background-color: ${lighterBackgroundColor};
        --background-border-color: ${backgroundBorderColor};
        --checkout-blue: #1063ff;

        --navbar-background-color: ${navbar.backgroundColor};
        --navbar-button-color: ${navbar.buttonColor};
        --navbar-hint-color: ${navbar.hintColor};
        --navbar-text-color: ${navbar.textColor};
        --navbar-upperbar-color: ${navbar.upperBarColor};
      }

      body {
        background-color: ${backgroundColor};
        color: ${textColor};
      }

      .textColor {
        color: ${textColor};
      }

      .lightTextColor {
        color: ${lightenColor(textColor, 150)};
      }

      .descriptionTextColor {
        color: ${descriptionTextColor};
      }

      .backgroundColor {
        background-color: ${backgroundColor};
      }

      .lighterBackgroundColor {
        background-color: ${lighterBackgroundColor};
      }

      .secondaryBackgroundColor {
        background-color: ${backgroundBorderColor};
      }

      .backgroundBorderColor {
        border-color: ${backgroundBorderColor};
      }

      .content {
        background-color: ${lighterBackgroundColor};
        color: ${textColor};
      }

      .divider {
        background-color: ${backgroundBorderColor};
      }

      .primaryColor {
        color: ${color};
      }

      .primaryColorHover {
        &:hover {
          color: ${color};
        }
      }

      .primaryBackgroundColor {
        background-color: ${color};
      }

      .primaryBackgroundColor10 {
        background-color: var(--primary-color-10);
      }

      .primaryBorderColor {
        border-color: ${color};
      }

      .orion_primary,
      .orion_primary:active,
      .orion_primary:hover {
        background-color: ${color};
      }

      a {
        color: ${color};
      }

      #nprogress .bar {
        background: ${lightPrimary};
      }

      #nprogress .peg {
        box-shadow: 0 0 10px ${lightPrimary}, 0 0 5px ${lightPrimary};
      }

      .button-v3-primary {
        background-color: ${color};
      }

      .focus\:button-v3-primary:focus {
        --tw-ring-color: ${color};
      }
    `}</style>
  )
}
