import React from 'react'
import MessageProvider from '@packages/justo-parts/lib/components/Provider/Message'
import {WebsiteInitialDataProvider} from '@page-components/Layout/InitialDataContext'
import ApolloWrapper from '@providers/apollo/ApolloWrapper'
import {GlobalState} from '@providers/GlobalState'
import Session from '@providers/Session'
import ViewportContext from '@providers/ViewportContext'
import WebsiteIdContext from '@providers/WebsiteIdContext'
import dynamic from 'next/dynamic'

import '@providers/locale'

const NonSsrRoot = dynamic(() => import('./NonSsrRoot'), {ssr: false})

export default function Root({
  children,
  websiteId,
  viewport
}: {
  children: React.ReactNode
  websiteId?: string
  viewport?: 'mobile' | 'desktop'
}) {
  return (
    <ApolloWrapper>
      <GlobalState>
        <ViewportContext.Provider value={viewport}>
          <WebsiteIdContext.Provider value={websiteId}>
            <WebsiteInitialDataProvider websiteId={websiteId}>
              <MessageProvider>
                <Session>
                  <NonSsrRoot />
                  {children}
                </Session>
              </MessageProvider>
            </WebsiteInitialDataProvider>
          </WebsiteIdContext.Provider>
        </ViewportContext.Provider>
      </GlobalState>
    </ApolloWrapper>
  )
}
