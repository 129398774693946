import React from 'react'
import classnames from 'classnames'

import styles from './styles.module.css'

export default function Loading(props) {
  const {color, className} = props

  return (
    <div className={styles.container}>
      <div className={classnames(styles.item, className)} style={{borderColor: color}} />
    </div>
  )
}
