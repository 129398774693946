import encryptString from '@page-components/AnalyticsV2/utils/encryptString'

export function formatGTMUserInfo(userInfo, extraInfo) {
  if (!userInfo) return null
  const formattedUserInfo = {
    email: userInfo.email,
    phone: userInfo.phone ? userInfo.phone.replace('+', '') : null,
    firstName: userInfo.profile.firstName,
    lastName: userInfo.profile.lastName,
    country: extraInfo?.countryCode
  }
  const fieldsNotToEncrypt = ['country']
  for (const [key, value] of Object.entries(formattedUserInfo)) {
    if (!fieldsNotToEncrypt.includes(key)) {
      formattedUserInfo[key] = encryptString(value)
    }
  }
  return formattedUserInfo
}
