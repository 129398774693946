import isServerSide from '@helpers/misc/isServerSide'
import useWebsiteId from '@hooks/useWebsiteId'
import {clientNames} from '@providers/services'
import {useApolloQuery} from 'apollo-hooks'
import gql from 'graphql-tag'

export function useGetAnalyticsSessionInfo(service: string) {
  const websiteId = useWebsiteId()
  const {analyticsSessionInfo} = useApolloQuery({
    query: gql`
      query getUserAnalyticsSessionInfo($websiteId: ID, $service: String) {
        analyticsSessionInfo(websiteId: $websiteId, service: $service) {
          _id
          sessionInfo {
            clientId
            sessionId
          }
        }
      }
    `,
    variables: {websiteId, service},
    omit: isServerSide() || !websiteId,
    clientName: clientNames.preferences,
    fetchPolicy: 'cache-first',
    partial: true,
  })
  return analyticsSessionInfo?.sessionInfo ?? {}
}

export function setAnalyticSessionInfoMutation() {
  return gql`
    mutation setAnalyticsSessionInfo(
      $websiteId: ID!
      $service: String!
      $sessionInfo: AnalyticsSessionInfoInput!
    ) {
      setAnalyticsSessionInfo(websiteId: $websiteId, service: $service, sessionInfo: $sessionInfo)
    }
  `
}
