import React from 'react'
import BaseStyles from '@components/Layout/BaseStyles'
import logger from '@helpers/logger'
import getUserFriendlyErrorInfo from '@helpers/misc/getUserFriendlyErrorInfo'
import isServerSide from '@helpers/misc/isServerSide'
import Container from '@packages/justo-parts/lib/components/Container'
import getEnv from '@providers/getEnv'
import {useRouter} from 'next/router'

import PermissionsError from './PermissionsError'

import styles from './styles.module.css'

export default function ErrorComponent({error, statusCode}: {error: Error; statusCode?: number}) {
  const router = useRouter()
  if (!error) return null

  const {errorInfo, message: friendlyMessage} = getUserFriendlyErrorInfo(error)
  const message = error?.message ?? friendlyMessage

  const getRetries = () => {
    const retries = router.query?.retries ?? 0
    return parseInt(retries as string, 10) ?? 0
  }

  const shouldRetry = () => {
    return getEnv() === 'prod' && getRetries() < 3
  }

  if (shouldRetry() && !isServerSide()) {
    setTimeout(() => {
      router.replace({
        pathname: window.location.pathname,
        query: {retries: getRetries() + 1}
      })
    }, 5000)
  }

  if (message.includes("User doesn't has permissions")) {
    return <PermissionsError error={error} />
  }

  if (message.includes('chunk')) {
    setTimeout(() => {
      router.reload()
    }, 1000)

    return (
      <div className={styles.container}>
        <BaseStyles />
        <div className={styles.title}>
          <Container>Actualizando versión del sitio</Container>
        </div>
        <div className={styles.message}>
          <Container>
            <div>Estamos actualizando la versión de este sitio web</div>
          </Container>
          <br />
          <Container>Cargando...</Container>
        </div>
      </div>
    )
  }
  logger.warn(`Rendering error component ${error.message}`)

  return (
    <div className={styles.container}>
      <BaseStyles />
      <div className={styles.title}>
        <Container>🙈 Ocurrió un Error</Container>
        <i>¡ Lo sentimos !</i>
        {statusCode && (
          <i>
            <b>Error {statusCode}</b>
          </i>
        )}
      </div>
      <div className={styles.message}>
        <div>{friendlyMessage}</div>
        {errorInfo.map((info, index) => (
          <pre key={index}>{info}</pre>
        ))}
        <br />
      </div>
      {shouldRetry() ? (
        <div className={styles.retry}>
          <Container>Intentando nuevamente...</Container>
        </div>
      ) : null}
    </div>
  )
}
