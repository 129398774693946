import gql from 'graphql-tag'

export default gql`
  fragment importantMessage on Query {
    website(websiteId: $websiteId) {
      _id
      navbarConfiguration {
        importantMessageText
      }
    }
  }
`
