import {useContext} from 'react'
import isServerSide from '@helpers/misc/isServerSide'
import {Context} from '@providers/GlobalState'

export const getSavedValue = function (key: string, defaultValue?: any) {
  if (isServerSide()) return defaultValue
  const savedValue = sessionStorage.getItem(key)
  if (savedValue === null) {
    return defaultValue
  } else {
    return JSON.parse(savedValue)
  }
}

export default function useSessionStorageState(key: string, defaultValue?: any) {
  const value = getSavedValue(key, defaultValue)
  const forceUpdate = useContext(Context)

  const setValue = newValue => {
    sessionStorage.setItem(key, JSON.stringify(newValue))
    forceUpdate()
  }

  return [value, setValue]
}
