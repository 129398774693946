import gql from 'graphql-tag'

export default gql`
  fragment websiteRoutes on Query {
    website(websiteId: $websiteId) {
      _id
      active
      pages {
        _id
        path
      }
      countryCode
    }
  }
`
