import cLogger from '@helpers/logger/conditionalLogger'
import getCurrencyCode from '@i18n/getCurrencyCode'
import {
  AnalyticsService,
  ImplementationOptions,
} from '@page-components/AnalyticsV2/types/analyticsServicesTypes'
import useInitialData from '@page-components/Layout/useInitialData'

import useLoadUserInfo from '../../hooks/useLoadUserInfo'
import {ERROR_GETTING_INSTANCE, ERROR_SENDING_EVENT} from '../errorMessages'

import formatPixelUserInfo from './formatPixelUserInfo'

export const ANALYTICS_META = 'meta'
export interface ReactPixel {
  pageView: any
  track: any
  trackCustom: any
  trackSingle: any
  trackSingleCustom: any
  fbq: any
  revokeConsent: any
  grantConsent: any
}

interface MetaImplementationConfiguration {
  verbose: boolean
  isEnabled: boolean
  pixelIds: string[]
  currencyCode: string
  userInfoPromise: any
  website: any
}
class MetaImplementation implements AnalyticsService {
  private verbose: boolean
  private pixelIds: string[]
  private currencyCode: string
  private userInfoPromise: any
  private website: any
  private pixelController: ReactPixel | null = null

  public name = ANALYTICS_META
  public isInternal = false
  public isEnabled: boolean

  constructor(conf: MetaImplementationConfiguration) {
    this.verbose = conf.verbose
    this.isEnabled = conf.isEnabled
    this.pixelIds = conf.pixelIds
    this.currencyCode = conf.currencyCode
    this.userInfoPromise = conf.userInfoPromise
    this.website = conf.website
  }

  async initReactPixel(pixelIds: string[], pixelUser?: any) {
    if (!pixelIds.length) return
    await import('react-facebook-pixel')
      .then(x => x.default)
      .then(rpx => {
        this.pixelController = rpx
        pixelIds.forEach(pixelId => {
          rpx.init(pixelId, pixelUser, {autoConfig: true, debug: this.verbose})
        })
        rpx.pageView()
      })
  }

  async initialize() {
    if (!this.isEnabled || this.pixelController) return
    const pixelUser = formatPixelUserInfo(await this.userInfoPromise, {
      countryCode: this.website?.countryCode,
    })
    return this.initReactPixel(this.pixelIds, pixelUser)
  }

  async sendEventToMeta(eventName: string, data?: any, eventExtraData?: {eventID: string}) {
    if (!this.pixelController) {
      cLogger(this.verbose).warn(ERROR_GETTING_INSTANCE(ANALYTICS_META))
      return
    }
    try {
      this.pixelController.fbq('track', eventName, data, eventExtraData)
    } catch (error) {
      cLogger(this.verbose).warn(ERROR_SENDING_EVENT(ANALYTICS_META), {error})
    }
  }

  async trackProductView(product) {
    return this.sendEventToMeta('ViewContent', {
      content_name: product.name,
      content_type: 'product',
      content_ids: [product._id],
      content_category: product.categories.length ? product.categories[0].internalName : null,
      value: product.availabilityAt?.finalPrice,
      currency: this.currencyCode,
    })
  }

  async trackAddToCart(productWithState) {
    const {state} = productWithState
    const product = productWithState
    return this.sendEventToMeta('AddToCart', {
      content_name: product.name,
      content_type: 'product',
      content_ids: [product._id],
      content_category: product.categories.length ? product.categories[0].internalName : null,
      contents: [{id: product._id, quantity: state.amount}],
      value: (product.availabilityAt?.finalPrice ?? 0) * state.amount,
      currency: this.currencyCode,
    })
  }

  async trackInitCheckout(preferences) {
    return this.sendEventToMeta('InitiateCheckout', {
      value: preferences?.cart.itemsPrice,
      num_items: preferences?.cart?.items.reduce((sum, item) => item.amount + sum, 0),
      currency: this.currencyCode,
      content_ids: preferences?.cart?.items?.map(item => {
        return item.product._id
      }),
      contents: preferences?.cart?.items?.map(item => {
        return {id: item.product._id, quantity: item.amount}
      }),
    })
  }

  async trackPurchase(order) {
    return this.sendEventToMeta(
      'Purchase',
      {
        value: order.totalPrice,
        num_items: order.totalItems,
        currency: this.currencyCode,
        content_type: 'product',
        content_ids: order.items.map(item => {
          return item.product._id
        }),
        contents: order.items.map(item => {
          return {id: item.product._id, quantity: item.amount}
        }),
      },
      {eventID: `${order.websiteId}-${order._id}`},
    )
  }

  async setUserId(_userId) {
    console.log('Meta TBD: Implement setUserId function')
  }

  async trackEventAsDefault(_event, _properties) {
    console.log('Meta TBD: Implement trackEvent function')
  }

  async trackScreenView(_properties) {
    //It isn't required because it's done automatically in each page by the plugin, even multiple time
    console.log('Meta TBD: Implement trackScreenView function')
  }
}

let MetaImplementationSingleton: MetaImplementation
// Meta Documentation https://developers.facebook.com/docs/meta-pixel/reference
function useMetaImplementation(options: ImplementationOptions): AnalyticsService {
  const verbose = options.verbose ?? false
  const initialData = useInitialData()
  const {integrations: config, website} = initialData
  const allPixelIds = [
    config?.integrations?.facebookPixelId,
    config?.justoAdsPixelId,
    ...(config?.additionalPixels ?? []),
  ]
  const pixelIds = Array.from(new Set(allPixelIds.filter(pixel => pixel && pixel.length > 0)))
  const isEnabled = !!pixelIds.length
  const currencyCode = getCurrencyCode()
  const userInfoPromise = useLoadUserInfo(isEnabled)
  const configObject = {
    verbose,
    isEnabled,
    pixelIds,
    currencyCode,
    userInfoPromise,
    website,
  }

  if (MetaImplementationSingleton) {
    return MetaImplementationSingleton
  }
  MetaImplementationSingleton = new MetaImplementation(configObject)
  return MetaImplementationSingleton
}

export default useMetaImplementation
