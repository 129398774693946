import cLogger from '@helpers/logger/conditionalLogger'
import useWebsiteId from '@hooks/useWebsiteId'
import GetCurrencyCode from '@i18n/getCurrencyCode'
import useInitialData from '@page-components/Layout/useInitialData'
import {clientNames} from '@providers/services'
import {useMutate} from 'apollo-hooks'
import GA4React from 'ga-4-react'
import {usePathname, useSearchParams} from 'next/navigation'
import {Router, useRouter} from 'next/router'

import {
  setAnalyticSessionInfoMutation,
  useGetAnalyticsSessionInfo,
} from '../../hooks/analyticsSessionInfo'
import {AnalyticsService, ImplementationOptions} from '../../types/analyticsServicesTypes'
import {ERROR_GETTING_INSTANCE, ERROR_INITIALIZING, ERROR_SENDING_EVENT} from '../errorMessages'

import {ga4TagConf} from './ga4TagConf'

export const ANALYTICS_GA4 = 'ga4'

interface GA4ImplementationConfiguration {
  verbose: boolean
  isEnabled: boolean
  sessionInfo: {clientId: string; sessionId: string} | null
  sessionInfoMutate: any // Replace `any` with the appropriate type if known
  currencyCode: string
  tagId: string
  websiteId: string
  opts: {
    initTimeout?: number
  }
}

class GA4Implementation implements AnalyticsService {
  private GA4ReactInstance: any
  private isInitialized = false
  private initPromise: Promise<void>
  private opts: {initTimeout?: number}
  private verbose: boolean
  private baseProperties: any
  private debug: boolean
  private sessionInfoMutate: any
  private sessionInfo: any
  private isProd: boolean
  private currencyCode: string
  private websiteId: string
  private tagId: string

  public isEnabled = false
  public isInternal = false
  public name = ANALYTICS_GA4

  constructor(conf: GA4ImplementationConfiguration) {
    this.debug = false
    this.verbose = conf.verbose
    this.opts = conf.opts
    this.isEnabled = conf.isEnabled ?? false
    this.sessionInfo = conf.sessionInfo
    this.sessionInfoMutate = conf.sessionInfoMutate
    this.currencyCode = conf.currencyCode
    this.websiteId = conf.websiteId
    this.tagId = conf.tagId
  }

  async sendEventToGA4(action, additionalData) {
    const ga4 = GA4React.getGA4React()
    if (!ga4) {
      cLogger(this.verbose).warn(ERROR_GETTING_INSTANCE(ANALYTICS_GA4))
      return
    }
    try {
      ga4.gtag('event', action, {...additionalData})
    } catch (error) {
      cLogger(this.verbose).warn(ERROR_SENDING_EVENT(ANALYTICS_GA4), {error})
    }
  }

  async trackProductView(product) {
    const ga4Data = {
      items: [
        {
          item_id: product._id,
          item_name: product.name,
          item_category: product.categories.length ? product.categories[0].internalName : null,
          price: product.availabilityAt?.finalPrice,
          quantity: 1,
        },
      ],
      currency: this.currencyCode,
      value: product.availabilityAt?.finalPrice,
    }
    return this.sendEventToGA4('view_item', ga4Data)
  }

  async trackAddToCart(productWithState) {
    const {state} = productWithState
    const product = productWithState
    const ga4Data = {
      items: [
        {
          item_id: product._id,
          item_name: product.name,
          item_category: product.categories.length ? product.categories[0].internalName : null,
          price: product.availabilityAt?.finalPrice,
          quantity: state.amount,
        },
      ],
      currency: this.currencyCode,
      value: (product.availabilityAt?.finalPrice ?? 0) * state.amount,
    }
    return this.sendEventToGA4('add_to_cart', ga4Data)
  }

  async trackInitCheckout(preferences) {
    const items = preferences?.cart?.items.map(item => {
      return {
        item_id: item.product._id,
        item_name: item.product.name,
        price: item.priceWithDiscounts,
        discount: item.priceWithoutDiscounts - item.priceWithDiscounts,
        quantity: item.amount,
      }
    })
    const ga4Data = {
      currency: this.currencyCode,
      value: preferences.cart.totalPrice,
      items,
    }

    return this.sendEventToGA4('begin_checkout', ga4Data)
  }

  async trackPurchase(order) {
    const items = order.items.map(item => {
      return {
        item_id: item.product._id,
        item_name: item.productName,
        price: item.unitPrice,
        discount: item.baseUnitPrice - item.unitPrice,
        quantity: item.amount,
      }
    })
    const ga4Data = {
      transaction_id: `${order.websiteId}-${order._id}`,
      currency: this.currencyCode,
      value: order.totalPrice,
      items,
    }
    return this.sendEventToGA4('purchase', ga4Data)
  }

  async setUserId(_userId) {
    console.log('GA4 TBD: Implement setUserId function')
  }

  async trackEventAsDefault(_event, _properties) {
    console.log('GA4 TBD: Implement trackEvent function')
  }

  async trackScreenView(_properties) {
    //It's triggered every time the router fires the event 'routeChangeComplete'. See init.
    console.log('GA4 TBD: Implement trackScreenView function')
  }

  handleRouteChange(ga4) {
    Router.events.on('routeChangeComplete', url => {
      ga4.pageview(url)
    })
  }

  async handleGA4Initialization(ga4) {
    const ga4ClientIdPromise = ga4TagConf(ga4, 'get', this.tagId, 'client_id')
    const ga4SessionIdPromise = ga4TagConf(ga4, 'get', this.tagId, 'session_id')
    Promise.allSettled([ga4ClientIdPromise, ga4SessionIdPromise]).then(results => {
      const ga4ClientId = results[0]?.status === 'fulfilled' ? results[0].value : null
      const ga4SessionId = results[1]?.status === 'fulfilled' ? results[1].value : null
      if (ga4ClientId && ga4SessionId) {
        this.sessionInfoMutate({
          mutation: setAnalyticSessionInfoMutation(),
          variables: {
            websiteId: this.websiteId,
            service: ANALYTICS_GA4,
            sessionInfo: {clientId: ga4ClientId, sessionId: ga4SessionId},
          },
          clientName: clientNames.preferences,
        })
      }
    })
  }

  async initialize() {
    if (!this.isEnabled) return
    const initGA4 = (codeV4: string) => {
      if (GA4React.isInitialized()) return
      const timeout = 10_000
      const customConfig = {
        debug_mode: this.verbose,
        ...(this.sessionInfo?.clientId &&
          this.sessionInfo?.sessionId && {
            client_id: this.sessionInfo.clientId,
            session_id: this.sessionInfo.sessionId,
          }),
      }
      const ga4react = new GA4React(codeV4, customConfig, null, timeout)
      ga4react.initialize().then(
        ga4 => {
          this.handleRouteChange(ga4)
          this.handleGA4Initialization(ga4)
        },
        err => {
          cLogger(this.verbose).warn(ERROR_INITIALIZING(ANALYTICS_GA4), {error: err})
        },
      )
    }

    return initGA4(this.tagId)
  }
}

let Ga4ImplementationSingleton: GA4Implementation

function useGA4Implementation(options: ImplementationOptions): AnalyticsService {
  const verbose = options.verbose ?? false
  const {integrations: initialConfig} = useInitialData()
  const router = useRouter()
  const tagId = initialConfig?.integrations?.googleAnalyticsV4Id
  const isEnabled = !!tagId
  const currencyCode = GetCurrencyCode()
  const sessionInfoMutate = useMutate()
  const sessionInfo = useGetAnalyticsSessionInfo(ANALYTICS_GA4)
  const websiteId = useWebsiteId()
  // This is to remove session params from previous session info management implementation
  const pathname = usePathname()
  const searchParams = useSearchParams()
  if (searchParams.get('GAClientId') || searchParams.get('GASessionId')) {
    const updatedSearchParams = new URLSearchParams(searchParams.toString())
    updatedSearchParams.delete('GAClientId')
    updatedSearchParams.delete('GASessionId')
    router.replace(`${pathname}?${updatedSearchParams}`, undefined, {shallow: true})
  }

  const config = {
    verbose,
    opts: null,
    isEnabled,
    sessionInfo,
    sessionInfoMutate,
    currencyCode,
    websiteId,
    tagId,
  }
  if (Ga4ImplementationSingleton) {
    return Ga4ImplementationSingleton
  }
  Ga4ImplementationSingleton = new GA4Implementation(config)
  return Ga4ImplementationSingleton
}

export default useGA4Implementation
