let commitHash = null

if (
  process.env.NODE_ENV !== 'development' &&
  process.env.JUSTO_ENV !== 'local' &&
  process.env.RUNNING_ON_VERCEL !== 'true'
) {
  try {
    commitHash = require('../commitHash.module.js')
  } catch {
    console.warn(
      'Could not find commitHash.module.js (generated at CI build). Defaulting commit hash to `local`.'
    )
  }
}

module.exports.getCommitHash = () => commitHash
