import isServerSide from '@helpers/misc/isServerSide'
import getEnv from '@providers/getEnv'
import services from '@providers/services'

import {OrionApolloClient} from './createApolloClient/orion'
import createApolloClient from './createApolloClient'

const env = getEnv()
const clients: {[key: string]: OrionApolloClient<any>} = {}

// NOTE: This is a list of services that should not restore cache on the client
const disableCacheRestore = ['preferences']

// TODO: Allow overriding all clients
export default function getClients() {
  // Prevent caching the clients on the server
  // This is to avoid memory leaks
  if (Object.keys(clients).length > 0 && !isServerSide()) return clients

  for (const key in services) {
    const service = services[key]
    const {urls} = service
    const url = urls[env]
    clients[key] = createApolloClient({
      clientName: key,
      endpointURL: url,
      useSubscriptions: isServerSide() ? false : service.useSubscriptions,
      restoreCache: isServerSide() === false && disableCacheRestore.includes(key) === false
    })
  }

  return clients
}
