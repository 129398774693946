import {GA4ReactResolveInterface} from 'ga-4-react/dist/models/gtagModels'

export async function ga4TagConf(
  ga4: GA4ReactResolveInterface,
  method: string,
  ga4Code: string,
  value: string
) {
  return new Promise((resolve, reject) => {
    ga4.gtag(method, ga4Code, value, result => {
      resolve(result)
    })
    setTimeout(() => {
      reject('timeout')
    }, 300)
  })
}
