import cLogger from '@helpers/logger/conditionalLogger'
import {
  setAnalyticSessionInfoMutation,
  useGetAnalyticsSessionInfo,
} from '@page-components/AnalyticsV2/hooks/analyticsSessionInfo'
import {ANALYTICS_V2_FORCE} from '@page-components/AnalyticsV2/hooks/useLazyAnalyticsV2'
import IsAmplitudeAvailable from '@page-components/AnalyticsV2/implementations/amplitude/IsAmplitudeAvailable'
import {
  AnalyticsService,
  ImplementationOptions,
} from '@page-components/AnalyticsV2/types/analyticsServicesTypes'
import {clientNames} from '@providers/services'
import {useMutate} from 'apollo-hooks'

import {ERROR_INITIALIZING, ERROR_SENDING_EVENT} from '../errorMessages'

import {sessionReplayPlugin} from '@amplitude/plugin-session-replay-browser'
import {Session} from '@page-components/AnalyticsV2/types/sessionTypes'
import getEnv from '@providers/getEnv'
import useBaseProperties from './baseProperties'
import {DEV_AMPLITUDE_APIKEY, PROD_AMPLITUDE_APIKEY} from './config'

export const ANALYTICS_AMPLITUDE = 'amplitude'
const SESSION_REPLAY_SAMPLE_RATE = 0.015

interface AmplitudeImplementationConfiguration {
  verbose: boolean
  env: string
  isEnabled: boolean
  baseProperties: any // Replace `any` with the appropriate type if known
  sessionInfo: {clientId: string; sessionId: string} | null
  sessionInfoMutate: any // Replace `any` with the appropriate type if known
  opts: {
    initTimeout?: number
  }
}

class AmplitudeImplementation implements AnalyticsService {
  private Amplitude: any
  private isInitialized: boolean
  private initPromise: Promise<void>
  private opts: {initTimeout?: number}
  private verbose: boolean
  private baseProperties: any
  private debug: boolean
  private sessionInfoMutate: any
  private sessionInfo: any
  private env: string
  private isProd: boolean

  public isEnabled = false
  public isInternal = true
  public name = ANALYTICS_AMPLITUDE

  constructor(conf: AmplitudeImplementationConfiguration) {
    this.debug = false
    this.verbose = conf.verbose
    this.opts = conf.opts
    this.isEnabled = conf.isEnabled ?? false
    this.baseProperties = conf.baseProperties
    this.sessionInfo = conf.sessionInfo
    this.sessionInfoMutate = conf.sessionInfoMutate
    this.env = conf.env
    this.isProd = this.env === 'prod'
  }

  async initialize() {
    if (!this.isEnabled || this.isInitialized) return
    if (this.initPromise instanceof Promise) return this.initPromise

    this.initPromise = import('@amplitude/analytics-browser').then(
      ({init, Types, getSessionId, getUserId}) => {
        const amplitudeApiKey = this.isProd ? PROD_AMPLITUDE_APIKEY : DEV_AMPLITUDE_APIKEY
        const logLevel = this.isProd
          ? Types.LogLevel.Error
          : this.debug
            ? Types.LogLevel.Debug
            : Types.LogLevel.Warn
        try {
          return init(amplitudeApiKey, {
            logLevel: logLevel,
            ...(this.sessionInfo?.sessionId && {sessionId: this.sessionInfo.sessionId}),
            ...(this.sessionInfo?.clientId && {userId: this.sessionInfo.clientId}),
            defaultTracking: {
              attribution: true,
              pageViews: {
                trackOn: 'attribution',
                eventType: 'detected-referral',
              },
              sessions: false,
              formInteractions: false,
              fileDownloads: false,
            },
          }).promise.then(() => {
            const newSessionId = getSessionId()
            const newClientId = getUserId()
            this.updateAnalyticsSessionData(newSessionId.toString(), newClientId)
            this.isInitialized = true
          })
        } catch (error) {
          cLogger(this.verbose).warn(ERROR_INITIALIZING(ANALYTICS_AMPLITUDE), {error})
        }
      },
    )
  }

  async sendEventToAmplitude(eventType: string, properties?: any, eventOptions?: any) {
    import('@amplitude/analytics-browser').then(amplitude => {
      try {
        amplitude.track(eventType, {...this.baseProperties, ...properties}, {...eventOptions})
        cLogger(this.verbose).info(`Sending ${eventType} to Amplitude with`, {
          ...this.baseProperties,
          ...properties,
          ...eventOptions,
        })
      } catch (error) {
        cLogger(this.verbose).warn(ERROR_SENDING_EVENT(ANALYTICS_AMPLITUDE), error)
      }
    })
  }

  async trackProductView(product) {
    if (!this.isEnabled) return
    const {extraProperties} = product
    return this.sendEventToAmplitude('product-selected', {
      actionLocation: extraProperties?.actionLocation,
      productId: product._id,
      productName: product.name,
    })
  }

  async trackAddToCart(productWithState) {
    if (!this.isEnabled) return
    const {state, extraProperties} = productWithState
    const product = productWithState
    const modifiers = product.modifiers ?? []
    const requiredModifiers = modifiers.filter(modifier => !modifier.optional)
    const productDiscount = Math.round(
      product.availabilityAt?.basePrice - product.availabilityAt?.finalPrice,
    )

    return this.sendEventToAmplitude('product-added', {
      actionLocation: extraProperties?.actionLocation,
      menuId: extraProperties?.menuId,
      productId: product._id,
      productName: product.name,
      categoryId: product.categories?.length ? product.categories[0]._id : '',
      categoryName: product.categories?.length ? product.categories[0].name : '',
      productDescription: product.description,
      productAmount: state.amount,
      fullPrice: product.availabilityAt?.basePrice,
      productDiscount: productDiscount > 0 ? productDiscount : 0,
      productTotalPrice: (product.availabilityAt?.finalPrice ?? 0) * state.amount,
      amountRequiredModifiers: requiredModifiers.length,
      amountOptionalModifiers: modifiers.length - requiredModifiers.length,
      selectedOptionalModifiers: state.modifiers?.length - requiredModifiers.length,
    })
  }

  async trackInitCheckout(preferences) {
    if (!this.isEnabled) return
    await import('@amplitude/analytics-browser').then(amplitude => {
      const sessionReplayTracking = sessionReplayPlugin({
        sampleRate: this.isProd ? SESSION_REPLAY_SAMPLE_RATE : 1,
        debugMode: this.debug,
      })
      amplitude.add(sessionReplayTracking)
    })
    return this.sendEventToAmplitude('checkout-page-viewed', {
      cartItemsAmount: preferences?.cart?.items.reduce((sum, item) => item.amount + sum, 0),
      productAmount: preferences?.cart?.items?.length,
      subTotalPrice: preferences?.cart?.itemsPrice,
      isTipAccepted: preferences?.cart?.acceptsTip,
      deliveryAmountToPay: preferences?.cart?.deliveryFee,
      amountToPay: preferences?.cart?.totalPrice,
      isScheduledOrder: preferences?.time && preferences?.time !== 'now',
      timeLabel: preferences?.timeLabel,
      currentPreparationDuration: preferences?.store?.currentPreparationDuration,
      currentDeliveryDuration: preferences?.store?.currentDeliveryDuration,
      availablePaymentTypes: preferences?.store?.availablePaymentTypes,
    })
  }

  async trackPurchase(order) {
    if (!this.isEnabled) return
    return this.sendEventToAmplitude(
      'web-order-confirmed',
      {
        payMethod: order.paymentType,
        payStatus: ['cash', 'inStore', 'other'].includes(order.paymentType)
          ? order.paymentStatus
          : order.transaction?.status,
        cardType: order.transaction?.cardType,
        orderId: order._id,
      },
      {insert_id: `${order.websiteId}-${order._id}`},
    )
  }

  async trackEventAsDefault(eventType: string, properties?: any) {
    return this.sendEventToAmplitude(eventType, properties)
  }

  async setUserId(userSession: Session) {
    if (!this.isEnabled || !userSession?.userId) return
    import('@amplitude/analytics-browser').then(amplitude => {
      try {
        amplitude.setUserId(userSession.userId)
        cLogger(this.debug).info('Setup user id on Amplitude', userSession.userId)
        const newSessionId = amplitude.getSessionId()
        const newClientId = amplitude.getUserId()
        this.updateAnalyticsSessionData(newSessionId.toString(), newClientId)
      } catch (error) {
        cLogger(this.debug).warn(error)
      }
    })
  }

  async updateAnalyticsSessionData(sessionId: string, clientId: string) {
    this.sessionInfoMutate({
      mutation: setAnalyticSessionInfoMutation(),
      variables: {
        websiteId: this.baseProperties.websiteId,
        service: ANALYTICS_AMPLITUDE,
        sessionInfo: {
          ...(sessionId && {sessionId}),
          ...(clientId && {clientId}),
        },
      },
      clientName: clientNames.preferences,
    })
  }

  async trackScreenView(_properties) {
    //We only record relevant views individually because we have a quote limit of events monthly
    console.log('TBD: Implement trackScreenView function')
  }
}

//Documentation https://www.docs.developers.amplitude.com/data/sdks/browser-2/
let amplitudeImplementationSingleton: AmplitudeImplementation
function useAmplitudeImplementation(options: ImplementationOptions): AnalyticsService {
  const verbose = options.verbose ?? false
  const isEnabled = IsAmplitudeAvailable() || ANALYTICS_V2_FORCE
  const baseProperties = useBaseProperties()
  const sessionInfo = useGetAnalyticsSessionInfo(ANALYTICS_AMPLITUDE)
  const sessionInfoMutate = useMutate()
  const env = getEnv()
  if (amplitudeImplementationSingleton) {
    return amplitudeImplementationSingleton
  }
  const config = {
    verbose,
    env,
    isEnabled,
    baseProperties,
    sessionInfo,
    sessionInfoMutate,
    opts: null,
  }
  amplitudeImplementationSingleton = new AmplitudeImplementation(config)
  return amplitudeImplementationSingleton
}

export default useAmplitudeImplementation
