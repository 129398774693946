import logger from '@helpers/logger'
import getClient from '@providers/apollo/getClient'
import gql from 'graphql-tag'

import {
  getWebsiteIdFromDomain_cached,
  getWebsiteIdFromDomain_cachedVariables
} from './__generated__/getWebsiteIdFromDomain_cached'

export const getWebsiteIdFromDomainQuery = gql`
  query getWebsiteIdFromDomain_cached($domain: String) {
    website(domain: $domain) {
      _id
    }
  }
`

export default async function getWebsiteIdFromDomain(domain: string) {
  const startTime = new Date()
  const response = await getClient().query<
    getWebsiteIdFromDomain_cached,
    getWebsiteIdFromDomain_cachedVariables
  >({
    query: getWebsiteIdFromDomainQuery,
    variables: {
      domain
    }
  })

  const {data} = response
  
  if (!data.website) {
    logger.info(`No website found for domain: ${domain}`, {
      domain,
      data,
      duration: new Date().getTime() - startTime.getTime(),
      response
    })
  }

  return data.website?._id ?? null
}
