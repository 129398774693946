import getDevUrl from '@providers/url'
import camelCase from 'lodash/camelCase'
import keys from 'lodash/keys'

const services = {
  main: {
    omitAuth: false,
    useSubscriptions: true,
    urls: {
      local: `http://localhost:3000`,
      dev: getDevUrl,
      prod: 'https://websites-api.getjusto.com'
    }
  },
  mainNoAuth: {
    omitAuth: true,
    useSubscriptions: false,
    urls: {
      local: `http://localhost:3000`,
      dev: getDevUrl,
      prod: 'https://websites-api.getjusto.com'
    }
  },
  auth: {
    omitAuth: false,
    useSubscriptions: false,
    urls: {
      local: `http://localhost:4112`,
      dev: 'https://auth.service.bejusto.com',
      prod: 'https://auth.service.getjusto.com'
    }
  },
  example: {
    omitAuth: false,
    useSubscriptions: false,
    urls: {
      local: `http://localhost:4100`,
      dev: 'https://example-orionjs.service.bejusto.com',
      prod: 'https://example-orionjs.service.getjusto.com'
    }
  },
  buckets: {
    omitAuth: false,
    useSubscriptions: false,
    urls: {
      local: `http://localhost:4106`,
      dev: 'https://buckets.service.bejusto.com',
      prod: 'https://buckets.service.getjusto.com'
    }
  },
  reservations: {
    omitAuth: false,
    useSubscriptions: false,
    urls: {
      local: `http://localhost:4113`,
      dev: 'https://reservations.service.bejusto.com',
      prod: 'https://reservations.service.getjusto.com'
    }
  },
  data: {
    omitAuth: false,
    useSubscriptions: false,
    urls: {
      local: `http://localhost:4107`,
      dev: 'https://data.service.bejusto.com',
      prod: 'https://data.service.getjusto.com'
    }
  },
  finances: {
    omitAuth: false,
    useSubscriptions: false,
    urls: {
      local: `http://localhost:4105`,
      dev: 'https://finances.service.bejusto.com',
      prod: 'https://finances.service.getjusto.com'
    }
  },
  commander: {
    omitAuth: false,
    useSubscriptions: false,
    urls: {
      local: `http://localhost:4109`,
      dev: 'https://commander.service.bejusto.com',
      prod: 'https://commander.service.getjusto.com'
    }
  },
  linear: {
    omitAuth: false,
    useSubscriptions: false,
    urls: {
      local: `http://localhost:4107/linear`,
      dev: 'https://data.service.bejusto.com/linear',
      prod: 'https://data.service.getjusto.com/linear'
    }
  },
  'bank-transfers': {
    omitAuth: false,
    useSubscriptions: false,
    urls: {
      local: `http://localhost:4150`,
      dev: 'https://bank-transfers.service.bejusto.com',
      prod: 'https://bank-transfers.service.getjusto.com'
    }
  },
  experiments: {
    omitAuth: false,
    useSubscriptions: false,
    urls: {
      local: `http://localhost:4114`,
      dev: 'https://experiments.service.bejusto.com',
      prod: 'https://experiments.service.getjusto.com'
    }
  },
  payments: {
    omitAuth: false,
    useSubscriptions: false,
    urls: {
      local: `http://localhost:4118`,
      dev: 'https://payments.service.bejusto.com',
      prod: 'https://payments.service.getjusto.com'
    }
  },
  addresses: {
    omitAuth: false,
    useSubscriptions: false,
    urls: {
      local: `http://localhost:4051`,
      dev: 'https://addresses.service.bejusto.com',
      prod: 'https://addresses.service.getjusto.com'
    }
  },
  preferences: {
    omitAuth: false,
    useSubscriptions: false,
    urls: {
      local: `http://localhost:4120`,
      dev: 'https://preferences.service.bejusto.com',
      prod: 'https://preferences.service.getjusto.com'
    }
  },
  royalty: {
    omitAuth: false,
    useSubscriptions: false,
    urls: {
      local: `http://localhost:4121`,
      dev: 'https://royalty.service.bejusto.com',
      prod: 'https://royalty.service.getjusto.com'
    }
  }
}

type clientNamesType = keyof typeof services
export const clientNames: Record<clientNamesType, clientNamesType> = keys(services).reduce(
  (acc, key) => {
    acc[camelCase(key)] = key
    return acc
  },
  {} as any
)

export default services
