import getEnv from '@providers/getEnv'

import transports from './transports'

const silencedLogging = {
  exception: () => {},
  error: () => {},
  warn: () => {},
  info: () => {},
  debug: () => {}
}

function cLogger(v = false) {
  if (v) return transports[getEnv()]
  return silencedLogging
}

export default cLogger
