import isServerSide from '@helpers/misc/isServerSide'
import {localStorageGetItem} from '@hooks/useLocalStorageState'
import getQueryParam from '@packages/justo-parts/lib/helpers/getQueryParam'

import generateId from '../misc/generateId'

import getEphemeralDeviceId from './getEphemeralDeviceId'

// Using a different key while we migrate from preferences v1 to v2
// That way, we don't get collissions when we migrate a website from v1 to v2
const DEVICE_KEY = 'deviceIdV2'

export default function getDeviceId() {
  if (isServerSide()) return null

  const ephemeralDeviceId = getEphemeralDeviceId()
  if (ephemeralDeviceId) return ephemeralDeviceId

  const paramDeviceId = getQueryParam(DEVICE_KEY)
  if (paramDeviceId) {
    localStorage.setItem(DEVICE_KEY, paramDeviceId)
    return paramDeviceId
  }

  const savedId = localStorageGetItem(DEVICE_KEY)
  if (savedId) return savedId

  const generatedId = generateId(50)

  localStorage?.setItem(DEVICE_KEY, generatedId)
  return generatedId
}
