import isServerSide from '@helpers/misc/isServerSide'
import {localStorageGetItem} from '@hooks/useLocalStorageState'
import authURL from '@providers/url'
import dayjs from 'dayjs';

import saveJWT from './saveJWT'

const REFRESH_BLOCK_SECONDS = 60
let startedRefreshingAt;
let refreshPromise: Promise<void>


export default async function refreshJWT() {
  if (isServerSide()) return

  if (!localStorageGetItem('justo_jwt_refresh')) {
    console.log('removing session because no refresh token')
    await saveJWT({})
    return
  }

  if (startedRefreshingAt && dayjs().isBefore(startedRefreshingAt.add(REFRESH_BLOCK_SECONDS, 'seconds'))) {
    return refreshPromise
  }

  refreshPromise = new Promise(async (resolve, reject) => {
    startedRefreshingAt = dayjs()
    const response = await fetch(`${authURL}/auth/refreshToken`, {
      method: 'POST',
      headers: {
        'X-ORION-REFRESH': localStorageGetItem('justo_jwt_refresh') || null
      }
    })

    const result = await response.json()

    if (result.error || !result.token) {
      console.log('Error refreshing JWT. Will reset Session')
      startedRefreshingAt = undefined
      await saveJWT({})
      reject()
      return
    }

    await saveJWT({token: result.token})
    resolve()
  })

  await refreshPromise
  refreshPromise = null
  startedRefreshingAt = null
  return

}

