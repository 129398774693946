import {NextRequest} from 'next/server'

export function viewportFromUserAgent(userAgent = ''): 'mobile' | 'desktop' {
  const safeUserAgent = typeof userAgent === 'string' ? userAgent : ''
  const isMobile = !!safeUserAgent.match(/Mobi/i)

  return isMobile ? 'mobile' : 'desktop'
}

export default function viewportFromRequest(req: NextRequest): 'mobile' | 'desktop' {
  return viewportFromUserAgent(req.headers.get('user-agent'))
}
