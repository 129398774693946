export function isJustoApp() {
  try {
    const userAgent = navigator.userAgent
    return userAgent.includes('JustoApp')
  } catch {
    return false
  }
}

export function getSafeAreaInsets(): {top: number; bottom: number; left: number; right: number} {
  return (
    (window as any).safeAreaInsets || {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    }
  )
}
