import cLogger from '@helpers/logger/conditionalLogger'
import GetCurrencyCode from '@i18n/getCurrencyCode'
import useLoadUserInfo from '@page-components/AnalyticsV2/hooks/useLoadUserInfo'
import useInitialData from '@page-components/Layout/useInitialData'
import TagManager from 'react-gtm-module'

import {AnalyticsService, ImplementationOptions} from '../../types/analyticsServicesTypes'
import {ERROR_SENDING_EVENT} from '../errorMessages'

import {formatGTMUserInfo} from './formatUserInfo'

export const ANALYTICS_GTM = 'gtm'

interface GTMImplementationConfiguration {
  verbose: boolean
  isEnabled: boolean
  currencyCode: string
  tagId: string
  website: any
  userInfoPromise: any // Replace `any` with the appropriate type if known
}

class GTMImplementation implements AnalyticsService {
  private verbose: boolean
  private currencyCode: string
  private tagId: string
  private website: any
  private userInfoPromise: any
  public isEnabled: boolean
  public name = ANALYTICS_GTM
  public isInternal = false

  constructor(conf: GTMImplementationConfiguration) {
    this.verbose = conf.verbose
    this.isEnabled = conf.isEnabled
    this.currencyCode = conf.currencyCode
    this.tagId = conf.tagId
    this.website = conf.website
    this.userInfoPromise = conf.userInfoPromise
  }

  async initialize() {
    if (!this.isEnabled) return
    return TagManager.initialize({gtmId: this.tagId})
  }

  async sendEventToGTM(dataLayer: any) {
    cLogger(this.verbose).info('Setting in dataLayer:', {dataLayer})
    try {
      TagManager.dataLayer({dataLayer})
    } catch (error) {
      cLogger(this.verbose).warn(ERROR_SENDING_EVENT(ANALYTICS_GTM), {error})
    }
  }

  async trackProductView(product) {
    const gtmData = {
      event: 'view_item',
      ecommerce: {
        items: [
          {
            item_id: product._id,
            item_name: product.name,
            item_category: product.categories?.length ? product.categories[0].internalName : null,
            price: product.availabilityAt?.finalPrice,
            quantity: 1,
          },
        ],
        currency: this.currencyCode,
        value: product.availabilityAt?.finalPrice,
      },
    }

    await this.sendEventToGTM(gtmData)
  }

  async trackAddToCart(productWithState) {
    const {state} = productWithState
    const product = productWithState
    const gtmData = {
      event: 'add_to_cart',
      ecommerce: {
        items: [
          {
            item_id: product._id,
            item_name: product.name,
            item_category: product.categories.length ? product.categories[0].internalName : null,
            price: product.availabilityAt?.finalPrice,
            quantity: state.amount,
          },
        ],
        currency: this.currencyCode,
        value: (product.availabilityAt?.finalPrice ?? 0) * state.amount,
      },
    }

    return this.sendEventToGTM(gtmData)
  }

  async trackInitCheckout(preferences) {
    const userInfo = await this.userInfoPromise
    const gtmUserInfo = formatGTMUserInfo(userInfo, {countryCode: this.website?.countryCode})
    const items = preferences?.cart?.items.map(item => {
      return {
        item_id: item.product._id,
        item_name: item.product.name,
        price: item.priceWithDiscounts,
        discount: item.priceWithoutDiscounts - item.priceWithDiscounts,
        quantity: item.amount,
      }
    })
    const gtmData = {
      event: 'begin_checkout',
      ecommerce: {
        currency: this.currencyCode,
        value: preferences.cart.totalPrice,
        items,
      },
      ...(gtmUserInfo && {userInfo: gtmUserInfo}),
    }
    await this.sendEventToGTM({ecommerce: {}})
    return this.sendEventToGTM(gtmData)
  }

  async trackPurchase(order) {
    const userInfo = await this.userInfoPromise
    const gtmUserInfo = formatGTMUserInfo(userInfo, {countryCode: this.website?.countryCode})
    const items = order.items.map(item => {
      return {
        item_id: item.product._id,
        item_name: item.productName,
        price: item.unitPrice,
        discount: item.baseUnitPrice - item.unitPrice,
        quantity: item.amount,
      }
    })
    const gtmData = {
      event: 'purchase',
      ecommerce: {
        transaction_id: `${order.websiteId}-${order._id}`,
        currency: this.currencyCode,
        value: order.totalPrice,
        items,
      },
      ...(gtmUserInfo && {userInfo: gtmUserInfo}),
    }
    await this.sendEventToGTM({ecommerce: {}})
    return this.sendEventToGTM(gtmData)
  }

  async setUserId(_userId) {
    console.log('GTM TBD: Implement setUserId function')
  }

  async trackEventAsDefault(_event, _properties) {
    console.log('GTM TBD: Implement trackEvent function')
  }

  async trackScreenView(_properties) {
    //It can be setup directly in GTM settings if a page change should or not trigger something
    console.log('GTM TBD: Implement trackScreenView function')
  }
}

let GtmImplementationSingleton: GTMImplementation

function useGTMImplementation(options: ImplementationOptions): AnalyticsService {
  const verbose = options.verbose ?? false
  const {integrations: config, website} = useInitialData()
  const tagManagerId = config?.integrations?.tagManagerId
  const isEnabled = !!tagManagerId
  const currencyCode = GetCurrencyCode()
  const userInfoPromise = useLoadUserInfo(isEnabled)
  const configObject = {
    verbose,
    isEnabled,
    currencyCode,
    tagId: tagManagerId,
    website,
    userInfoPromise: userInfoPromise,
  }

  if (GtmImplementationSingleton) {
    return GtmImplementationSingleton
  }

  GtmImplementationSingleton = new GTMImplementation(configObject)
  return GtmImplementationSingleton
}

export default useGTMImplementation
