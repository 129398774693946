import isServerSide from '@helpers/misc/isServerSide'
import getQueryParam from '@packages/justo-parts/lib/helpers/getQueryParam'

const STORAGE_KEY = 'ephemeralDeviceId'
const QUERY_PARAM = 'edi'

const queryParamDeviceId = (() => {
  if (isServerSide()) return null
  return getQueryParam(QUERY_PARAM) as string
})()

export default function getEphemeralDeviceId() {
  if (isServerSide()) return null

  if (queryParamDeviceId) {
    sessionStorage.setItem(STORAGE_KEY, queryParamDeviceId)
    return queryParamDeviceId
  }

  const savedId = sessionStorage.getItem(STORAGE_KEY)
  if (savedId) return savedId

  
}
