import gql from 'graphql-tag'

export default gql`
  fragment websiteLocale on Website {
    country {
      locale
      code
    }
  }
`
