export default {
  es_CL: 'CLP',
  es_MX: 'MXN',
  es_PE: 'PEN',
  es_CO: 'COP',
  en_US: 'USD',
  es_EC: 'USD',
  es_CR: 'CRC',
  es_AR: 'ARS'
}
