import {ApolloLink, split} from '@apollo/client'
import {getMainDefinition} from 'apollo-utilities'
import {OperationDefinitionNode} from 'graphql'

import createHttpLink from './createHttpLink'
import createWsLink from './createWsLink'
import isSsrMode from './isSsrMode'
import onError from './onError'

const isOperationDefinition = (definition): definition is OperationDefinitionNode => {
  return definition && definition.kind === 'OperationDefinition'
}

export default function createLink(options) {
  let links = [onError(options)]
  const ssrMode = isSsrMode(options)

  if (!ssrMode) {
    if (options.useSubscriptions) {
      const httpLink = createHttpLink(options)
      const wsLink = createWsLink(options)
      const link = split(
        ({query}) => {
          const definition = getMainDefinition(query)
          if (!isOperationDefinition(definition)) {
            return false
          }
          const {kind, operation} = definition
          return kind === 'OperationDefinition' && operation === 'subscription'
        },
        wsLink,
        httpLink
      )
      links.push(link)
    } else {
      links.push(createHttpLink(options))
    } 
  } else {
    // If ssr: Override all links with the single http link
    links = [createHttpLink(options)]
  }
  return ApolloLink.from(links)
}
