import doesCountryRequireCookies from '@helpers/websites/doesCountryRequireCookies'
import useLocalStorageState from '@hooks/useLocalStorageState'
import useInitialData from '@page-components/Layout/useInitialData'

export default function useCookiesAccepted() {
  const [prompCookies] = useLocalStorageState('prompCookies', null)
  const initialData = useInitialData()
  if (doesCountryRequireCookies(initialData?.website?.countryCode) === false) return true

  if (!prompCookies) return false
  return JSON.parse(prompCookies).userClickAccepted
}
