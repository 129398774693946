export default function getUserFriendlyErrorInfo(error) {
  if (!error?.message) {
    return {errorInfo: [], message: 'Unkown error'}
  }
  const hashMap = {}
  let errorMessage = error.message
  let stack = error.stack
  if (error.errors && error.errors.length > 0) {
    if (!errorMessage) errorMessage = error.errors[0].message
    if (!stack) stack = error.errors[0].stack
    error.errors
      .splice(0, 5) // to limit the number of hash displayed
      .forEach(err => (err.extra && err.extra.hash ? (hashMap[err.extra.hash] = true) : null))
  }
  const errorInfo = Object.keys(hashMap)
  let message = errorMessage
  if (message.startsWith('Client is not allowed to perform this action')) {
    message = 'No tienes suficientes permisos para ver esta sección'
  } else if (errorInfo.length === 0) {
    message = 'Si el error persiste, no dudes en contactarnos'
    errorInfo.push(errorMessage)
    if (stack) errorInfo.push(stack)
  } else {
    message = 'Si el error persiste, puedes enviar los siguientes códigos a soporte:'
  }

  return {
    errorInfo,
    message
  }
}
