import {Severity} from '@sentry/types'

import JustoError from './JustoError'

const logException = (exception, data = {}) => {
  import('@sentry/minimal').then(({captureException}) => {
    captureException(exception, {
      tags: {
        errorLevel: 'JustoException'
      },
      level: 'error' as Severity,
      extra: data
    })
    console.error(`Justo<exception>`, exception, data)
  })
}

const logError = (message, data = {}) => {
  import('@sentry/minimal').then(({captureException}) => {
    captureException(new JustoError(message, data), {
      tags: {
        errorLevel: 'JustoError'
      },
      level: 'error' as Severity,
      extra: data
    })
    console.error(`Justo<error>`, message, data)
  })
}

const logWarn = (message, data = {}) => {
  import('@sentry/minimal').then(({captureMessage}) => {
    captureMessage(new JustoError(message, data, 'warning').message, {
      tags: {
        errorLevel: 'JustoWarning'
      },
      level: 'warning' as Severity,
      extra: data
    })
    console.warn(`Justo<warn>`, message, data)
  })
}

const logDebug = (message, data = {}) => {
  console.log(`Justo<debug>`, message, data)
  console.trace()
}

const logInfo = (message, data = {}) => {
  console.info(`Justo<info>`, message, data)
}

const log = (message, data = {}) => {
  console.info(`Justo<log>`, message, data)
  console.trace()
}

const envConfig = {
  local: {
    exception: logInfo,
    error: log,
    warn: log,
    info: logInfo,
    debug: logDebug
  },
  prod: {
    exception: logException,
    error: logError,
    warn: logWarn,
    info: logInfo,
    debug: logDebug
  },
  dev: {
    exception: logException,
    error: logError,
    warn: logWarn,
    info: logInfo,
    debug: logDebug
  }
}

export default envConfig
