import getDeviceId from '@helpers/auth/getDeviceId'

const ODDS = [
  '000', //12.5%
  '001', //25.0%
  '010', //37.5%
  '011', //50.0%
  '100', //62.5%
  '101' //75.0%
  //"110",//87.5%
  //"111" //100%
]
function matchBinaryOdds(str: string, odds: Array<string>) {
  const values = []
  for (let i = 0; i < odds[0].length; i++) {
    values.push(str.charCodeAt(i) % 2)
  }
  return odds.includes(values.join(''))
}

export default function IsAmplitudeAvailable() {
  const deviceId = getDeviceId()
  return deviceId && matchBinaryOdds(deviceId, ODDS)
}
