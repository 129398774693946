import React from 'react'
import Loading from '@components/Loading'
import Container from '@packages/justo-parts/lib/components/Container'

import styles from './styles.module.css'

export default function SsgFallback() {
  return (
    <Container className={styles.container}>
      <Loading className={styles.loading} />
    </Container>
  )
}
