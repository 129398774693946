import isServerSide from '../isServerSide'

const mainDomains = [
  'localhost',
  'getjusto.com',
  'bejusto.com',
  'local',
  'orioneat.com',
  'orioneats.com',
  ...(process.env.REACT_APP_DOMAIN ? [process.env.REACT_APP_DOMAIN] : [])
]

export default function getCurrentMainDomain() {
  if (isServerSide()) return null
  return mainDomains.find(
    domain =>
      String(window.location.hostname) === domain ||
      String(window.location.hostname).endsWith(`.${domain}`)
  )
}
