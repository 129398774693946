// eslint-disable-next-line simple-import-sort/imports
import {viewportFromUserAgent} from '@helpers/misc/viewportFromUserAgent'
import type {AppContext, AppProps, NextWebVitalsMetric} from 'next/app'
import App from 'next/app'
import Head from 'next/head'
import Router, {useRouter} from 'next/router'
import {appWithTranslation} from 'next-i18next'
import NProgress from 'nprogress' //nprogress module
import {ErrorBoundary} from '@sentry/nextjs'
import 'reflect-metadata'

// NOTE: These can't be extracted into a separate JS file since Next needs all global css files to be loaded directly in _app
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import 'react-multi-carousel/lib/styles.css'
import 'rc-slider/assets/index.css'
import '@styles/layout/tailwind.css' // This goes on top to allow overriding
import '@styles/globals.css'
import '@styles/loading.css'
import '@packages/justo-parts/lib/styles.css'
import '@styles/layout/paginated-base.css'
import '@styles/layout/main.css'
import '@styles/layout/flexbox.css'
import '@styles/layout/helpers.css'
import '@styles/layout/title.css'
import '@styles/layout/content.css'
import '@styles/layout/buttons.css'
import '@styles/layout/animations.css'
import '@styles/layout/label.css'
import '@styles/layout/paginated.css'
import '@styles/layout/texts.css'
import '@styles/layout/tabs.css'
import '@styles/layout/input.css'
import '@styles/layout/colors.css'
import '@styles/layout/slider.css'
import '@styles/layout/small-form.css'
import '@styles/layout/animate.css'
import '@styles/layout/loading.css'
import '@styles/layout/new-styles.css'
import 'nprogress/nprogress.css'
import 'react-credit-cards/es/styles-compiled.css'
import Root from '@providers/Root'
import ErrorComponent from '@components/ErrorComponent'
import useReloadOnNewVersion from '@hooks/useReloadOnNewVersion'
import getWebsiteIdFromDomain from '@data/queries/website/websiteIdFromDomain'
import getNormalizedHostnameFromReq, {
  getNormalizedHostname
} from '@helpers/middleware/getNormalizedHostname'
import NoWebsite from '@page-components/NoWebsite'
import SsgFallback from '@components/SsgFallback'

//Binding events.
Router.events.on('routeChangeStart', () => NProgress.start())
Router.events.on('routeChangeComplete', () => NProgress.done())
Router.events.on('routeChangeError', () => NProgress.done())
NProgress.configure({showSpinner: false})

function MyApp({Component, pageProps}: AppProps) {
  useReloadOnNewVersion()

  const router = useRouter()
  if (router.isFallback) {
    return <SsgFallback />
  }

  if (!pageProps.websiteId) {
    return <NoWebsite />
  }

  return (
    <div>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, viewport-fit=cover"
        />
      </Head>
      <ErrorBoundary fallback={<ErrorComponent error={null} />}>
        <Root viewport={pageProps.viewport} websiteId={pageProps.websiteId}>
          <Component {...pageProps} />
        </Root>
      </ErrorBoundary>
    </div>
  )
}

MyApp.getInitialProps = async (appContext: AppContext) => {
  const appProps = await App.getInitialProps(appContext)
  const req = appContext.ctx.req
  const hostname = req
    ? getNormalizedHostnameFromReq(req)
    : getNormalizedHostname(appContext.ctx?.query?.hostname)
  const viewport = req ? viewportFromUserAgent(req.headers['user-agent']) : 'desktop'
  const websiteId = await getWebsiteIdFromDomain(hostname)

  return {
    pageProps: {
      ...appProps.pageProps,
      viewport,
      websiteId
    }
  }
}
export default appWithTranslation(MyApp)

export function reportWebVitals(metric: NextWebVitalsMetric) {
  console.debug(metric)
}
