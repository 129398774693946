import isServerSide from '@helpers/misc/isServerSide'
import {localStorageGetItem} from '@hooks/useLocalStorageState'

export default function getJWTSavedData() {
  if (isServerSide()) return {}
  try {
    return JSON.parse(localStorageGetItem('justo_jwt_data')) || {}
  } catch (error) {
    return {}
  }
}
