import React from 'react'
import Head from 'next/head'

import styles from './styles.module.css'

export default function NoWebsite() {
  return (
    <>
      <Head>
        <meta name="robots" content="noindex" />
      </Head>
      <div className={styles.container}>
        <img src="/images/no-website.svg" className={styles.image} alt="website not found" />
        <div className={styles.title}>Este sitio web no está configurado correctamente</div>
        <div>
          <a href="https://getjusto.com" className={styles.logo}>
            <img src="/logo-dark.svg" alt="logo justo" />
          </a>
        </div>
      </div>
    </>
  )
}
